/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "UrlShortenerApi",
            "endpoint": "https://5tlgq0y5ee.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:48326beb-9312-4a10-9371-774d9253f68c",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_uw9Ex96Fz",
    "aws_user_pools_web_client_id": "1cq8pl84s2t2sdt61u9m2b3o82",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "url-shortener-amp-20191105205326-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://url-shortener-amp-20191105205326-hostingbucket-prod.s3-website-eu-west-1.amazonaws.com",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Shortcut-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "Whitelist-prod",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
