import React, { Component } from 'react';
import Amplify, { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import awsconfig from 'aws-exports';
import { oauth } from 'oauth';
import { withCookies } from 'react-cookie';
import Router from 'components/router';
import LoadingIndicator from 'components/loading-indicator';
import Header from 'components/header';
import Button from 'components/button';

Amplify.Logger.LOG_LEVEL = 'ERROR';

class App extends Component {
    constructor(props) {
        super(props);

        // Restoring ouath_pkce_key session storage key from cookie is here to fix IE/Edge issues related to
        // disappearing session storage as explained here: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser
        // Once Amplify.js fix the problem this code should be removed. 
        const { cookies } = props;
        const pkce = cookies.get('ouath_pkce_key');
        sessionStorage.setItem('ouath_pkce_key', pkce);

        awsconfig.oauth = oauth;
        Amplify.configure(awsconfig);
    }

    _isMounted = false;
    state = { user: null };

    savePkce = () => {
        const { cookies } = this.props;
        const pkce = sessionStorage.getItem('ouath_pkce_key');
        cookies.set('ouath_pkce_key', pkce);
    };

    signIn = () => {
        localStorage.clear();
        Auth.federatedSignIn({ customProvider: 'AzureAD' })
            .then(() => this.savePkce());
    };

    componentDidMount = () => {
        this._isMounted = true;

        const removePkce = () => {
            const { cookies } = this.props;
            cookies.remove('ouath_pkce_key');
        };

        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                    removePkce();
                    Auth.currentAuthenticatedUser({ bypassCache: true })
                        .then((user) => this.setState({ user }));
                    break;
                case 'signIn_failure':
                    removePkce();
                    break;
                default:
                    break;
            }
        });

        Auth.currentAuthenticatedUser({ bypassCache: true })
            .then(user => {
                if (this._isMounted) {
                    this.setState({ user });
                }
            })
            .catch(() => {
                if (!localStorage['amplify-redirected-from-hosted-ui']) {
                    console.log('Redirecting to federated sign in due to missing amplify-redirected-from-hosted-ui local storage entry.');
                    Auth.federatedSignIn({ customProvider: 'AzureAD' })
                        .then(() => this.savePkce());
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { user } = this.state;

        return user
            ? (
                <>
                    <Header />
                    <section className="content">
                        <Router
                            roles={user.signInUserSession.accessToken.payload['cognito:groups']}
                            distinguishedName={user.signInUserSession.idToken.payload['custom:DistinguishedName']}
                        />
                        <LoadingIndicator />
                    </section>
                </>
            )
            : (<div><Button readOnly type="button" value={'Sign In'} onClick={() =>this.signIn()}/></div>)
    }
}

export default withCookies(App);
